import React, {useEffect, useRef} from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneVolume } from "@fortawesome/free-solid-svg-icons";

const PhoneCard = () => {
    
    const phoneRef = useRef(null);
    const h3Ref = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // If the element is in the viewport, add the animation class
              phoneRef.current.classList.add('animate-phone');
              phoneRef.current.classList.remove('hidden');
              
            }
          });
        },
        { threshold: 0.5 }
      );
  
      observer.observe(h3Ref.current);
  
      // Cleanup the observer when the component is unmounted
      return () => {
        observer.disconnect();
      };
    }, []);
  
    return (
        <div className='overflow-hidden mt-6 md:mt-0 md:w-5/12 lg:w-auto h-fit'>
        <FontAwesomeIcon ref={phoneRef} className="w-full relative text-center text-4xl text-blue-700 hidden md:text-5xl" icon={faPhoneVolume}/>
        <div className='text-center w-full mt-3'>
          <h3 ref={h3Ref} className='text-xl font-semibold text-blue-700 xl:text-2xl'>Atención telefonica</h3>
          <p className='mt-2 text-gray-600 dm-sans text-sm md:text-base'>Frutas y verduras de la mejor calidad, por el precio que a ti más te conviene</p>
        </div>
      </div>
        
    );
  };

  export default PhoneCard;