import React, {useEffect, useRef} from "react";

const AnimatedLine = () => {
    const lineRef = useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // If the element is in the viewport, add the animation class
              lineRef.current.classList.add('animate-line');
            }
          });
        },
        { threshold: 0.5 }
      );
  
      observer.observe(lineRef.current);
  
      // Cleanup the observer when the component is unmounted
      return () => {
        observer.disconnect();
      };
    }, []);
  
    return (
      <div className='w-1/6 lg:w-1/12 m-auto'>
        <div ref={lineRef} className='h-0.5 m-auto bg-blue-600 line'></div>
      </div>
    );
  };

  export default AnimatedLine;