const fruitImg = {
    frutilla: require('./11/11-11/frutilla.jpg'),
    "manzana red especial": require('./11/11-11/manzana-red-especial.jpg'),
    "manzana pink lady": require('./11/11-11/manzana-pink-lady.png'),
    cebolla: require('./11/11-11/cebolla.jpg'),
    lechuga: require('./11/11-11/lechuga.jpg'),
    "morrón verde": require('./11/11-11/morron-verde.jpg'),
    tomate: require('./11/11-11/tomato.jpg'),
    zanahoria: require('./11/11-11/zanahoria.jpg'),
    acelga: require('./11/11-11/acelga.jpg'),
    huevo: require('./11/11-11/carton-de-huevos.jpg'),
    durazno: require('./11/11-11/durazno.jpg'),
    choclo: require('./11/11-11/choclo.jpg'),
    espinaca: require('./11/11-11/espinaca.jpg'),
    kiwi: require('./11/11-11/kiwi.jpg'),
    "melón escrito": require('./11/11-11/melon-escrito.png'),
    "melón mediano": require('./11/11-11/melon-mediano.jpg'),
    alcachofa: require('./11/11-11/alcachofa.jpg'),
    "banana ecuador": require('./11/11-11/banana-ecuador.jpg'),
    berenjena: require('./11/11-11/berenjena.jpg'),
    "cebolla colorada": require('./11/11-11/cebolla-colorada.jpeg'),
    "cebolla importada": require('./11/11-11/cebolla-importada.jpg'),
    ajo: require('./11/11-11/garlic.jpg'),
    jengibre: require('./11/11-11/jengibre.jpg'),
    limón: require('./11/11-11/limon.jpg'),
    naranja: require('./11/11-11/naranja.jpg'),
    "plátano macho": require('./11/11-11/platano-macho.jpg'),
    puerro: require('./11/11-11/puerro1.jpg'),
    remolacha: require('./11/11-11/remolacha.jpg'),
    "zapallito de tronco": require('./11/11-11/zapalito-de-tronco.jpg'),
    "zapallo cabutia": require('./11/11-11/zapallo-kabutia.jpg'), 
    "zapallo criollo": require('./11/11-11/zapallo-criollo.jpg'),
    "palta hass": require('./11/11-11/palta.jpg'),
    mani: require('./11/11-11/mani.jpg'),
    coliflor: require('./11/11-11/coliflor.jpg'),
    brocoli: require('./11/11-11/brocoli.jpg'),
    "sandia baby": require('./11/11-11/Sandia-Baby.jpg'),
    alcahcofa: require('./11/11-11/alcachofa.jpg'),
    albahaca: require('./11/11-11/albahaca.jpeg'),
    arandanos: require('./11/11-11/arandanos.jpg'),
    "huevo de primavera": require('./11/11-11/huevo-de-primavera.jpg'),
    "huevo yumbo": require('./11/11-11/huevo-yumbo.jpg'),
    "mandarina murgott": require('./11/11-11/mandarina-murgott.jpg'),
    "mandarina malvacia": require('./11/11-11/mandarina-malvacia.jpg'),
    pomelo: require('./11/11-11/pomelo.jpg'),
    "acelga negra": require('./11/11-11/acelga-negra.jpg'),
    arándanos: require('./11/11-11/arandanos.jpg'),
    "tomate cherry": require('./11/11-11/tomate-cherry.jpg'),
    mango: require('./11/11-11/mango.jpg'),
    "pera importada": require('./11/11-11/pera-importada.jpg'),
    "tomate especial": require('./11/11-11/tomate-especial.jpg'),
    "tomate oferta": require('./11/11-11/tomate-oferta.jpg'),
    "zanahoria oferta": require('./11/11-11/zanahoria-oferta.jpg'),
}

export default fruitImg;
   