import { useState, useEffect } from "react";
import fruitImg from "../assets/fruitImg";
import cantidadTransform from "../utils/cantidadTransform";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhotoFilm } from "@fortawesome/free-solid-svg-icons";

import logo from "../assets/logo_nb.png";

const CreateImg = ({id, fruit}) => {
    const [price, setPrice] = useState(0);
    const [oldPrice, setOldPrice] = useState(0);
    const [offer, setOffer] = useState(false);
    const [cantidad, setCantidad] = useState(0);


// Reset the form when the id changes 
    useEffect(() => {
        if (fruit) {
            setPrice(fruit.price);
            setOldPrice(fruit.oldPrice ? fruit.oldPrice : "");
            setOffer(fruit.offer);
            setCantidad(fruit.cantidad ? fruit.cantidad : "");
        }

    }, [id, fruit]);

    return (
        <div className="bg-white aspect-square w-full flex flex-col items-center justify-around relative m-auto inset-0 md:w-5/6 xl:w-auto xl:h-full">
            {id === null ? (
                <div className="h-full w-full lg: p-16">
                    <h3 className="text-3xl md:text-5xl text-center mt-8 p-4 font-semibold text-gray-700">Crea una imagen</h3>
                    <p className="text-md md:text-lg text-center p-4 text-gray-500">Para poder crear una imagen para tus redes sociales, debes clickear sobre el producto que desees.</p>

                    <FontAwesomeIcon icon={faPhotoFilm} className="text-8xl lg:text-8xl w-full text-center text-gray-400 mt-8"/>
                </div>
            ) :( 
            <div className="py-8 lg:py-0">
                {
                    fruit.offer ? (
                        <div className="absolute bg-blue-800  top-3 right-3 py-2 px-4 text-gray-100 font-semibold md:text-2xl md:top-6 md:right-6 md:py-4 md:px-8">
                            OFERTA
                        </div>
                    ) : null
                }
                <img src={logo} alt="" className="absolute top-2 left-2  w-36 md:w-48 md:top-6 md:left-6"/>
                <img src={fruitImg[fruit.name.toLowerCase()]} alt="" className="max-h-56 mt-16 m-auto md:max-h-none md:max-w-xs lg:mt-0"/>
                <h3 className="text-3xl text-center font-semibold text-blue-800 md:text-4xl ">
                    {fruit.name.charAt(0).toUpperCase() + fruit.name.slice(1).toLowerCase()}
                    {fruit.cantidad && fruit.cantidad != 0 && fruit.cantidad != "0" ?  <span className='font-normal'> {" "}({cantidadTransform(`${fruit.cantidad}`)})</span> : null}
                </h3>
                <p className='text-2xl font-medium text-gray-700 text-center mt-4 md:text-3xl'>
                    {fruit.offer && fruit.oldPrice ? <><span className='text-gray-500 line-through'>${fruit.oldPrice}</span><span className='ml-3 text-gray-900 underline font-medium'>${fruit.price}</span></> : `$${fruit.price}`}
                </p>
            </div>
            )}
        </div>
    )
}

export default CreateImg
