import { useEffect, useRef } from 'react';

import aboutUsImage from '../assets/inicio/about-us.png';
import maymenImg from '../assets/inicio/mayor-menor.jpg';
import localImg from '../assets/inicio/local.jpg';

import AnimatedLine from '../animation/AnimatedLine';

import TruckCard from '../animation/TruckCard';
import CashCard from '../animation/CashCard';
import PhoneCard from '../animation/PhoneCard';
import BasketCard from '../animation/BasketCard';


  

function Home() {
    
    const locationRef = useRef(null);
    const maymenRef = useRef(null);
    const buttonMaymenRef = useRef(null);
    const buttonLocationRef = useRef(null);

    useEffect(() => {
      
        const observerL = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // If the element is in the viewport, add the animation class
              locationRef.current.classList.add('opacity-100',  'translate-y-0');
              locationRef.current.classList.remove('opacity-0','translate-y-10');
              
            }
          });
        },
        { threshold: 0.5 }
      );
  
      observerL.observe(buttonLocationRef.current);
  
      // Cleanup the observer when the component is unmounted
      return () => {
        observerL.disconnect();
      };
      
    }, []);

    useEffect(() => {
      
        const observerM = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // If the element is in the viewport, add the animation class
              maymenRef.current.classList.add('opacity-100',  'translate-y-0');
              maymenRef.current.classList.remove('opacity-0','translate-y-10');
              
            }
          });
        },
        { threshold: 0.5 }
      );
  
      observerM.observe(buttonMaymenRef.current);
  
      // Cleanup the observer when the component is unmounted
      return () => {
        observerM.disconnect();
      };
      
    }, []);


    return (
        <div className="bg-gray-100 flex flex-col justify-center items-center">
            <div className='h-screen'>            
                <div className='homeimg absolute inset-0'></div>
                <div className='left-0 top-0 flex flex-col w-full h-full absolute justify-center items-center'>
                    <h1 className="text-4xl  font-bold mb-4 text-gray-100 tracking-tight md:text-6xl lg:text-7xl">SIN FRONTERAS</h1>
                    <p className="md:text-xl dm-sans font-medium mb-8 text-gray-200">Todo lo que precises al alcance de tu mano</p>
                    <a href='/catalogo' className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded">
                        Ir al catálogo
                    </a>
                </div>
            </div>
            <div className='pb-12 w-full'>
                <h2 className="text-3xl w-full text-center font-bold text-blue-800 mb-4 tracking-tight md:text-4xl xl:text-5xl md:mb-6">¿Porqué elegirnos?</h2>
                <AnimatedLine/>
                <div className='w-5/6 m-auto flex flex-wrap justify-between mt-6 md:mt-16 lg:mt-2 xl:w-4/5'>
                    <div className='m-auto flex flex-col justify-evenly flex-wrap md:flex-row lg:flex-col md:w-full lg:m-0 lg:w-1/4'>
                        <CashCard/>
                        <BasketCard/>
                    </div>
                    <img src={aboutUsImage} alt="Imagen frutas y verduras" className='w-5/6 m-auto md:rotate-90 lg:rotate-0 md:w-1/2 lg:w-auto'/>
                    <div className='m-auto flex flex-col justify-evenly flex-wrap md:flex-row md:w-full lg:flex-col lg:m-0 lg:w-1/4'>
                        <TruckCard/>
                        <PhoneCard/>
                    </div>
                </div>
            </div>
            <div className='lg:h-[80vh] py-12 w-full bg-blue-950'>
                <div className=' m-auto h-full flex flex-wrap justify-between align-middle md:w-4/5 lg:w-11/12 2xl:w-4/5'>
                <div ref={locationRef} className='w-11/12 h-full flex flex-col justify-between transition-all ease-out duration-1000 opacity-0 translate-y-10 lg:w-5/12 m-auto lg:m-0 md:w-full'>
                    <div className='w-full m-auto'>
                        <h2 className="text-3xl w-full text-center font-semibold text-gray-100 mb-4 tracking-tight md:text-4xl xl:text-5xl">¿Dónde nos ubicamos?</h2>
                        <AnimatedLine/>
                        <p className='dm-sans text-gray-200 mt-8 text-center leading-relaxed text-sm md:text-base'>
                            Nos ubicamos en la Avenida Antonio Lussich entre las calles Juan Etchurry y Estanislao González, enfrente del cementerio de Maldonado, Uruguay.
                        </p>
                        <a ref={buttonLocationRef}  href='/contacto' className="w-5/6 block text-center m-auto bg-blue-700 hover:bg-blue-600 text-white font-semibold text-lg py-4 px-8 rounded mt-6 md:mt-12 md:w-1/3 lg:w-1/2 2xl:w-1/3"> 
                            Contactanos
                        </a>

                    </div>
                    
                </div>
                <img src={localImg} className="w-11/12 object-contain m-auto mt-12 lg:mt-0 lg:m-0 lg:w-1/2 md:w-full"/>
                </div>
                
                
            </div>
            <div className='lg:h-[80vh] py-12 w-full'>
                <div className='m-auto h-full w-full flex flex-wrap lg:flex-row-reverse justify-between align-middle md:w-4/5 lg:w-11/12 2xl:w-4/5'>
                <div className='w-11/12 h-full flex flex-col m-auto lg:m-0 justify-between md:w-full lg:w-5/12'>
                    <div ref={maymenRef} className='w-full transition-all ease-out duration-1000 opacity-0 translate-y-10 m-auto '>
                        <h2 className="text-3xl w-full text-center font-semibold text-blue-800 mb-4 tracking-tight leading-tight md:text-4xl xl:text-5xl">Vendemos por mayor y por menor</h2>
                        <AnimatedLine/>
                        <p className='dm-sans text-gray-600 mt-8 text-center leading-relaxed text-sm md:text-base'>
                        Descubre la frescura y calidad en nuestra selección de frutas, verduras y más, directamente del productor a tu mesa. En nuestra verdulería, te ofrecemos la posibilidad de adquirir nuestros productos tanto al por mayor como al por menor, garantizando siempre los mejores precios del mercado.
                        </p>
                        <a ref={buttonMaymenRef} href='/catalogo' className="w-5/6 block text-center  m-auto bg-blue-700 hover:bg-blue-600 text-white font-semibold text-lg py-4 px-8 rounded mt-6 md:mt-12 md:w-1/3 lg:w-1/2 2xl:w-1/3"> 
                            Ir al catálogo
                        </a>

                    </div>
                    
                </div>
                <img src={maymenImg} alt="" className='w-11/12 object-contain m-auto mt-12 lg:mt-0 lg:m-0 lg:w-1/2 md:w-full' />
                </div>
            </div>
            


        </div>
    );
}

export default Home;
