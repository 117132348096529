import React, {useEffect, useRef} from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSackDollar } from "@fortawesome/free-solid-svg-icons";

const CashCard = () => {
    
    const cashRef = useRef(null);
    const h3Ref = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // If the element is in the viewport, add the animation class
              cashRef.current.classList.add('animate-cash');
              cashRef.current.classList.remove('hidden');
              
            }
          });
        },
        { threshold: 0.5 }
      );
  
      observer.observe(h3Ref.current);
  
      // Cleanup the observer when the component is unmounted
      return () => {
        observer.disconnect();
      };
    }, []);
  
    return (
        <div className='overflow-hidden md:w-5/12 lg:w-auto  mt-6 md:mt-0 h-fit'>
        <FontAwesomeIcon ref={cashRef} className="w-full relative text-center text-4xl text-blue-700 hidden md:text-5xl" icon={faSackDollar}/>
        <div className='text-center w-full mt-3'>
          <h3 ref={h3Ref} className='text-xl font-semibold text-blue-700 xl:text-2xl'>Los mejores precios</h3>
          <p className='mt-2 text-gray-600 dm-sans text-sm md:text-base'>Frutas y verduras de la mejor calidad, por el precio que a ti más te conviene</p>
        </div>
      </div>
        
    );
  };

  export default CashCard;